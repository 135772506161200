import { getCVATStore } from 'cvat-store';
import { filterByName } from './filter-applicable-labels';
import config from 'config';
import { Canvas } from 'cvat-canvas-wrapper';
import { Label } from '../cvat-core-wrapper';

interface pixelSize {
    x: number;
    y: number;
}

const getPixelSizeByDimension = (label: Label, dimension: 'x' | 'y') => {
    const dimensionAttribute = filterByName(label.attributes, dimension)[0].values[0]
    return parseFloat(dimensionAttribute);
}

export function getPixleSize(): pixelSize {
    let pixelSize = { x: 1, y: 1 };
    const { labels } = getCVATStore().getState().annotation.job;
    const pixelSizeList = filterByName(labels, config.PIXEL_SIZE_LABEL_NAME);

    if (pixelSizeList) {
        try {
            const pixleSizeLabel = pixelSizeList[0];
            pixelSize.x = getPixelSizeByDimension(pixleSizeLabel, 'x');
            pixelSize.y = getPixelSizeByDimension(pixleSizeLabel, 'y');
        } catch (error) {
            pixelSize = {x: 0, y: 0}
            console.error('Ruler data is not correct! Autolabeling may not work as well.')
        }
    }
    return pixelSize;
}

export function getRealSize(points: any, canvasInstance: Canvas): number {
    // size: size in pixels
    const pixelSize = getPixleSize();
    const { scale } = canvasInstance.model.geometry;

    const dx = ((points[0].x - points[1].x) * pixelSize.x) ** 2;
    const dy = ((points[0].y - points[1].y) * pixelSize.y) ** 2;
    const distance = Math.sqrt(dx + dy);
    return distance / scale;
}
