import React, { useCallback, useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import Icon from '@ant-design/icons';
import MDEditor from '@uiw/react-md-editor/nohighlight';
import { Button, Modal } from 'antd';
import { ReportIcon } from 'icons';
import { getJobReport, saveJobReport, showErrorModal } from 'actions/annotation-actions';
import { connect, useSelector } from 'react-redux';
import { getCore } from 'cvat-core-wrapper';
import { CombinedState } from 'reducers';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface ModalOpenerProps {
    children: React.ReactNode;
    triggerText: string;
    onSave(): Promise<any>;
    onOpen(): void;
    footerExtension: React.ReactElement[];
}


const ModalOpener: React.FC<ModalOpenerProps> = (props: any) => {
    const { children, triggerText, onSave, footerExtension } = props;
    // eslint-disable
    const onOpen = props.onOpen || (() => {});
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [isSpinning, setSpinning] = useState<boolean>(false);

    useEffect(onOpen, [isVisible]);

    const showModal = (): void => {
        setIsVisible(true);
    };

    const handleOk = async (): Promise<void> => {
        setSpinning(true);
        onSave().finally(() => {
            setSpinning(false);
        });
    };

    const handleCancel = (): void => {
        setIsVisible(false);
    };

    const footer = [
        <Button key='back' type='primary' onClick={handleCancel}>
            Close
        </Button>,
        <Button loading={isSpinning} key='submit' type='primary' onClick={handleOk}>
            Save
        </Button>,
        ...footerExtension,
    ];

    return (
        <>
            {/* @ts-ignore */}
            <Icon className='cvat-fit-control' component={ReportIcon} onClick={showModal}>
                {triggerText}
            </Icon>
            {/* @ts-ignore */}
            {isVisible && (
                <Modal
                    okType='primary'
                    okText='Save'
                    cancelText='Cancel'
                    onOk={handleOk}
                    onCancel={handleCancel}
                    onClose={(e: Event) => {
                        e.stopPropagation();
                    }}
                    footer={footer}
                    title='Imaging Report'
                    visible={isVisible}
                    destroyOnClose
                    onChange={(e: Event) => console.log(e)}
                    width='70vw'
                    bodyStyle={{ overflowY: 'auto', height: '50vh' }}
                >
                    {children}
                </Modal>
            )}
        </>
    );
};

interface MarkdownEditorProps {
    onChange(markdown: string): void;
    content: string;
}

const MarkdownEditor = (props: MarkdownEditorProps) => {
    const { onChange, content } = props;
    const handleChange = (...args: any[]) => {
        onChange(args[0]); //args[0] is the content
    };
    const scrollingContainerRef = React.useRef(null);

    return (
        <ReactQuill
            className='cvat-report-editor'
            value={content}
            onChange={handleChange}
            // scrollingContainer={scrollingContainerRef.current}

            theme='snow'
            modules={{
                toolbar: [
                    [{ header: '1' }, { header: '2' }, { font: ['Serif'] }],
                    [{ size: [] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    ['link', 'image', 'video'],
                ],
            }}
            formats={[
                'header',
                'font',
                'size',
                'bold',
                'italic',
                'underline',
                'strike',
                'blockquote',
                'list',
                'bullet',
                'indent',
                'link',
                'image',
                'video',
            ]}
        />
    );
};

const mapStateToProps = (state: CombinedState) => {
    const reportModel = state.models.detectors.find((m) => m.id === 'pth-ultralytics-report');
    const { instance } = state.annotation.job;
    return { reportModel, jobInstance: instance };
};

function mapDispatchToProps(dispatch: any) {
    return {
        dispatch,
    };
}
function MarkdownEditorModal(props: any): React.ReactElement {
    const { reportModel, jobInstance, dispatch } = props;
    const jobId = jobInstance.id;
    const [mdContent, setMdContent] = useState('');
    const [isSpinning, setSpinning] = useState<boolean>(false);

    const onOpenModal = () => {
        const fetch = async () => {
            const report = await getJobReport(jobId);
            setMdContent(report);
        };
        fetch();
    };

    const generateReport = async () => {
        const frames = {};
        const frameNameKeywords = ['LCC', 'LMLO', 'RCC', 'RMLO'];
        for (let i = 0; i < jobInstance.frameCount; i++) {
            const data = await jobInstance.frames.get(i, undefined, undefined);
            for (let j of frameNameKeywords) {
                if (data.filename.toUpperCase().includes(j)) {
                    Object.assign(frames, { [i]: j });
                }
            }
        }

        return getCore()
            .lambda.call(jobInstance.taskId, reportModel, {
                frame: 0, // frame: 0 - no difference what number it is
                frames: frames,
                job: jobInstance.id,
            })
            .then((response: any) => {
                console.log(response);
                return response[0]['report'];
            })
            .catch((error: Error) => {
                console.error('Error generating report:', error);
                dispatch(showErrorModal(error));
                setSpinning(false);
            });
    };

    function handleAIAssist() {
        setSpinning(true);
        generateReport()
            .then((report: string) => {
                setMdContent(report);
                setSpinning(false);
            })
            .catch((error: Error) => {
                dispatch(showErrorModal(error));
                console.error('Error generating report:', error);
            });
    }
    const autoGenerateButton = [
        <Button loading={isSpinning} key='ai-assist' type='primary' onClick={handleAIAssist}>
            AI Assist
        </Button>,
    ];

    const handleMarkdownContent = useCallback((e: string): void => {
        setMdContent(e);
        console.log(e);
    }, []);

    async function handleSave(jobId: number, content: string) {
        return await saveJobReport(jobId, content);
    }

    return (
        <ModalOpener
            triggerText='Open Editor'
            onSave={(): any => handleSave(jobId, mdContent)}
            onOpen={onOpenModal}
            footerExtension={autoGenerateButton}
        >
            <MarkdownEditor content={mdContent} onChange={handleMarkdownContent} />
        </ModalOpener>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(MarkdownEditorModal);
